import React from "react"
import Slider from "react-slick"

const UserIcon = ({ color }) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="16px"
    height="16px"
    viewBox="0 0 45.532 45.532"
    fill={color}
  >
    <path
      d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765
S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53
c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012
c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592
c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"
    />
  </svg>
)

const StarIcon = ({ active }) => (
  <svg
    height="12px"
    viewBox="0 -10 511.98685 511"
    width="12px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
      fill={active ? "#FFD89E" : "#ccc"}
    />
  </svg>
)

const reviewItems = [
  {
    name: "Ilya DONSKOV",
    review:
      "Отличное приложение!<p>Домашние задания стали радостью для ребенка, готов повторять снова и&nbsp;снова! Тот редкий случай когда, технологии действительно служат на&nbsp;пользу. Есть теперь, что-то полезное делать на&nbsp;телефоне! Методика отличная, хороша и&nbsp;для группы и&nbsp;для семейного обучения! А&nbsp;с&nbsp;таким подспорьем, ещё легче пойдёт! С&nbsp;нетерпением ждём вторую часть!</>",
    color: "#FF905F",
    rating: 5,
    link:
      "https://apps.apple.com/ru/app/%D0%B2%D0%BE%D0%BB%D1%88%D0%B5%D0%B1%D0%BD%D0%BE%D0%B5-%D0%BF%D0%B8%D0%B0%D0%BD%D0%B8%D0%BD%D0%BE/id1462566372#see-all/reviews",
  },
  {
    name: "Lidia Sh",
    review:
      "Настоящий курс английского языка для детей. <p>Родители могут играть на&nbsp;английском с&nbsp;ребёнком, слушать песенки и&nbsp;повторять фразы.</p> <p>Работает! </p><p>Очень рекомендую.</p>",
    color: "#40AB85",
    rating: 5,
    link:
      "https://play.google.com/store/apps/details?id=ru.magicpiano.app&pli=1&reviewId=gp%3AAOqpTOG43IBIfH6mt5ZMrD-5OGwjiqQjQPpr23ZUcXwoJ77LRWdDDtt_DAGWjoBb0YEW6EqUI5Bv_tw-1uUmIw",
  },
  {
    name: "Елена Белякова",
    review:
      "Очень приятно играть с&nbsp;детьми и&nbsp;учить английский, графически прекрасно выполненное оформление, понятный интерфейс, а&nbsp;звуковое сопровождение просто супер! <p>Играем с&nbsp;детьми каждый день, очень полюбились нам песенки, напеваем их&nbsp;всей семьей. С&nbsp;этим приложением обучение это 100% развлечение!</p>",
    color: "#C96359",
    rating: 5,
    link:
      "https://play.google.com/store/apps/details?id=ru.magicpiano.app&pli=1&reviewId=gp%3AAOqpTOHVuGjN1KjPqMOoTT6liYhZiadSnxxO3iu1E-1HdrISPStYlovwVkoWuFAr8i4WGdTFzdOjDvYHiSMzZA",
  },
  {
    name: "Maria Shpikalova",
    review:
      "Отличный тренажёр. Начинали заниматься по&nbsp;книжке с&nbsp;карточками. Но&nbsp;современные дети лучше втянулись в&nbsp;приложение, и&nbsp;карточки теперь не&nbsp;ест младшая сестра и&nbsp;не&nbsp;мешает занятиям :) <p>Очень понятная методика, хорошая озвучка (авторы прислушались к&nbsp;отзывам на&nbsp;книгу и&nbsp;сделали озвучку носителем). Удобно, что дети могут сами повторять пройденные уроки. Стоит столько&nbsp;же, сколько и&nbsp;книга. </p>",
    color: "#62ABD8",
    rating: 5,
    link:
      "https://play.google.com/store/apps/details?id=ru.magicpiano.app&pli=1&reviewId=gp%3AAOqpTOGtxurUPLvUx-XDIph-x6mT0SjvDSP3B6FoNmllZtXxeVx-Gzy7h2Ru4mD2caVLmRB6lek1Rjj_MBPNWQ",
  },
  {
    name: "Ирина Куличкова",
    review:
      "Полностью с&nbsp;вами согласна! <p>С&nbsp;большим удовольствием играем и&nbsp;учимся одновременно. Все задание доступны и&nbsp;интересны.</p><p> Нам очень нравится угадайка и&nbsp;песенки петь под караоке 👍 </p> <p> Спасибо всем за&nbsp;идею и&nbsp;разработку! 🙏</p>",
    color: "#FFBE5F",
    rating: 5,
    link:
      "https://play.google.com/store/apps/details?id=ru.magicpiano.app&pli=1&reviewId=gp%3AAOqpTOG9i3WgKfQRQZtQ66iXc0TGdu3CFQaN3EoHlVq6xIE4itqdpJcLppoljQDyYFJg3W0LcgJXDsPw-eM4Zg",
  },
  {
    name: "Наталия Гончарова",
    review:
      "Очень нравится приложение, спасибо большое. Дочке 6&nbsp;лет, занимается с&nbsp;удовольствием. <p> Любимые задания&nbsp;&mdash; карта сокровищ, сказка, мячик-перевертыш. Нравится петь песни)</p><p> Удобно тем, что родители также легко могут подключиться, поиграть и&nbsp;позаниматься вместе с&nbsp;ребенком, все понятно и&nbsp;интересно. Через несколько уроков уже виден результат&nbsp;&mdash; конструирует простые фразы!</p>",
    color: "#4082AB",
    rating: 5,
    link:
      "https://play.google.com/store/apps/details?id=ru.magicpiano.app&pli=1&reviewId=gp%3AAOqpTOHFKSadyMDL0vNky3sLmpHFNEuqJMX7qDFb0ML6wJzPHlWCFNZ3GjYpDK_rWTV020igemWsWGWCZDbNKA",
  },
  {
    name: "София Щербакова",
    review:
      "Дочке 7&nbsp;лет. Просто, доступно, методично и&nbsp;в&nbsp;игровой форме проходит обучение английскому языку!<p> Каждый день по&nbsp;уроку с&nbsp;помощью Волшебного пианино ❤</p><p> Будем очень рады продолжению!</p>",
    color: "#5E3119",
    rating: 5,
    link:
      "https://play.google.com/store/apps/details?id=ru.magicpiano.app&pli=1&reviewId=gp%3AAOqpTOEpJ7mnO5OzNxSQdek75dXmDFOS1OHhVA1hm-RMSa5ps632QEnsiq9oIHePr4aoyUP9rffeELxptEqpXA",
  },
]

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  centerPadding: "60px",
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Rating = ({ rating }) =>
  [...Array(5)].map((x, i) => (
    <StarIcon key={`star-${i}`} active={i < rating} />
  ))

export const Reviews = () => (
  <div className="vp-reviews">
    <Slider {...sliderSettings}>
      {reviewItems.map((item, i) => (
        <div className="vp-review" key={`item-${i}`}>
          <h4 className="vp-review__name">
            <UserIcon color={item.color} /> {item.name}
          </h4>
          <div className="vp-review__rating">
            <Rating rating={item.rating} />
          </div>
          <div
            className="vp-review__text"
            dangerouslySetInnerHTML={{ __html: item.review }}
          />
          <a href={item.link} target="_blank" className="vp-review__link">
            Отзыв целиком
            <img src="/sign.svg" alt="external"/>
          </a>
        </div>
      ))}
    </Slider>
  </div>
)
