import React, { useState, useEffect, useLayoutEffect } from "react"

import im1 from "../im/im-1.png"
import im2 from "../im/im-2.png"
import im3 from "../im/im-3.png"
import im4 from "../im/im-4.png"
import im5 from "../im/im-5.png"
import im6 from "../im/im-6.png"
import im7 from "../im/im-7.png"
import im8 from "../im/im-8.png"
import im9 from "../im/im-9.png"
import im10 from "../im/im-10.png"
import im11 from "../im/im-11.png"
import im12 from "../im/im-12.png"
import im13 from "../im/im-13.png"

const ims = {
  im1,
  im2,
  im3,
  im4,
  im5,
  im6,
  im7,
  im8,
  im9,
  im10,
  im11,
  im12,
  im13,
}

const randomInt = (min, max) => {
  let rand = min + Math.random() * (max + 1 - min)
  return Math.floor(rand)
}

export const Phone = () => {
  const [mounted, setMounted] = useState(false)

  useLayoutEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div className="vp-phone__container">
      <img
        className="vp-phone__img"
        src="/phone.png"
        alt="приложение волшебное пианино"
      />
      <div className="vp-phone__round">
        {mounted && [...Array(8)].map((x, i) => <PhoneImg key={i} num={i} />)}
      </div>
      <div className="vp-phone__bottom-fade" />
    </div>
  )
}

const PhoneImg = ({ num }) => {
  const [img, setImg] = useState(ims[`im${randomInt(1, 13)}`])
  const [duration, setDuration] = useState(randomInt(3000, 5000))
  useEffect(() => {
    const interval = setInterval(() => {
      setImg(() => ims[`im${randomInt(1, 13)}`])
    }, duration)
    setDuration(() => duration)
    return () => clearInterval(interval)
  }, [])

  return (
    <img
      key={img}
      style={{ animationDuration: `${duration / 1000}s` }}
      className={`vp-phone-img vp-phone-img_${num}`}
      src={img}
      alt="card"
    />
  )
}
