import React from "react"

export const DownloadButton = ({ type }) => {
  const iOS = type === "ios"
  const link = iOS
    ? "https://apps.apple.com/ru/app/%D0%B2%D0%BE%D0%BB%D1%88%D0%B5%D0%B1%D0%BD%D0%BE%D0%B5-%D0%BF%D0%B8%D0%B0%D0%BD%D0%B8%D0%BD%D0%BE/id1462566372"
    : "https://play.google.com/store/apps/details?id=ru.magicpiano.magicpiano"

  return (
    <a href={link} className="vp-download-link">
      <img
        className="vp-download-link__logo"
        src={`${type}-logo.png`}
        alt={type}
      />
      <div className="vp-download-link__text">
        <span className="vp-download-link__cta">Загрузите в</span>
        <br />
        <span className="vp-download-link__store">
          {iOS ? "App Store" : "Google Play"}
        </span>
      </div>
    </a>
  )
}
