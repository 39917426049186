import React, { useState } from "react"
import useModal from "use-react-modal"
import Slider from "react-slick"

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
}

const videos = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

export const Videos = () => {
  const { isOpen, openModal, Modal, closeModal } = useModal()
  const [videoId, setVideo] = useState(null)

  return (
    <div className="vp-videos">
      <Slider {...sliderSettings}>
        {videos.map(x => (
          <div
            role="button"
            tabIndex="0"
            className="vp-video"
            key={`video${x}`}
            onClick={e => {
              setVideo(x)
              openModal(e)
            }}
          >
            <img
              className="vp-video__img"
              src={`/video/video${x}.jpg`}
              alt={`video${x}`}
            />
            <div className="vp-video__play" />
          </div>
        ))}
      </Slider>
      {isOpen && (
        <Modal>
          <div className="vp-video__player">
            <div
              className="vp-video__close"
              role="button"
              tabIndex="0"
              onClick={closeModal}
            >
              ×
            </div>
            <video width="300" height="300" controls="controls" autoplay>
              <source src={`/video/video${videoId}.mp4`} type="video/mp4;" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </Modal>
      )}
    </div>
  )
}
