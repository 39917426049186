import React from "react"

const faqItems = [
  {
    q: "Для кого подходит “Волшебное пианино”?",
    a:
      "Приложение предназначено для младших школьников, дошкольников&nbsp;и, конечно, их&nbsp;родителей. Почти во&nbsp;всех играх ребёнку обязательно нужен партнёр или помощник. Не&nbsp;бросайте его или ее&nbsp;наедине со&nbsp;смартфоном. Поиграйте вместе! Что может быть важнее общего дела и&nbsp;общей радости? Нам кажется, что ничего.",
  },
  {
    q: "Обязательно ли взрослому хорошо владеть английским?",
    a:
      "Все разминки, игры, песенки и&nbsp;тексты у&nbsp;нас полностью озвучены. Вы&nbsp;всегда можете воспользоваться любой подсказкой, посмотреть перевод или послушать, как произносит то&nbsp;или иное предложение профессиональный американский диктор.<p>Поэтому не&nbsp;стоит бояться!</p><p><strong>Все очень просто и&nbsp;понятно! И&nbsp;у&nbsp;вас обязательно получится!</strong></p>",
  },
  {
    q: "Сколько минут в&nbsp;день нужно заниматься?",
    a:
      "Продолжительность урока зависит от&nbsp;возраста и&nbsp;темперамента ребёнка. 8-9 летние дети иногда осваивают по&nbsp;нескольку уроков в&nbsp;день, а&nbsp;непоседливый 5-летка может устать уже через 15&nbsp;минут. Нет никаких жестких правил, важно ориентироваться на&nbsp;состояние ребёнка. Пусть занятия будут в&nbsp;радость всем участникам!",
  },
  {
    q: "Чему конкретно мой ребёнок научится через 62&nbsp;урока?",
    a:
      "<p>За&nbsp;62&nbsp;урока дети освоят три английских грамматических времени (Present Progressive, Future Simple и&nbsp;Present Simple), to&nbsp;be-конструкции и&nbsp;значительно расширят словарный запас. Дети смогут рассказать о&nbsp;том, что они умеют, а&nbsp;чего не&nbsp;умеют, что любят, а&nbsp;чего не&nbsp;любят, что у&nbsp;них есть, что они будут делать завтра и&nbsp;многое другое.</p><p>А&nbsp;первые свои предложения каждый ребёнок скажет уже на&nbsp;первом занятии!</p>",
  },
  {
    q: "Нужно&nbsp;ли знать алфавит, чтобы заниматься по&nbsp;приложению?",
    a:
      "Нет! Нам кажется очень важным сначала научить ребёнка говорить на&nbsp;иностранном языке, а&nbsp;уже потом браться за&nbsp;освоение чтения и&nbsp;письма. Все игры и&nbsp;задания в&nbsp;приложении рассчитаны на&nbsp;практику разговорной речи.",
  },
]

export const FAQ = () => (
  <div className="vp-faq">
    <div className="vp-section">
      {faqItems.map((item, i) => (
        <React.Fragment key={`item-${i}`}>
          <h4
            className="vp-faq__question"
            dangerouslySetInnerHTML={{ __html: item.q }}
          ></h4>
          <div
            className="vp-faq__answer"
            dangerouslySetInnerHTML={{ __html: item.a }}
          />
        </React.Fragment>
      ))}
    </div>
  </div>
)
