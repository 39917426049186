import React from "react"

const featursList = [
  {
    img: "/warmup.png",
    title: "Разминки",
    sub: "для моторно-двигательной памяти",
  },
  {
    img: "/song.png",
    title: "Песенки",
    sub: "для слуховой памяти",
  },
  {
    img: "/cards.png",
    title: "Мнемокарточки",
    sub: "для зрительной, ассоциативной и образной памяти",
  },
  {
    img: "/games.png",
    title: "Игры",
    sub: "для эмоциональной памяти",
  },
]

export const Features = () => (
  <div className="vp-features">
    {featursList.map(item => (
      <div className="vp-feature" key={item.img}>
        <img className="vp-feature__img" src={item.img} alt="{item.img}" />
        <div className="vp-feature__descr">
          <h4>{item.title}</h4>
          <div className="vp-feature__subtile">{item.sub}</div>
        </div>
      </div>
    ))}
  </div>
)
