import React from "react"
import { Phone } from "../components/Phone"
import { DownloadButton } from "../components/DownloadButton"
import { Features } from "../components/Features"
import { FAQ } from "../components/FAQ"
import { Reviews } from "../components/Reviews"
import { Videos } from "../components/Videos"
import { Helmet } from "react-helmet"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const CURRENT_YEAR = new Date().getFullYear()

const TITLE = "«Волшебное Пианино» - языковое приложение для детей"
const DESCRIPTION =
  "Cовременная игровая музыкальная методика изучения иностранных языков для детей от 4 до 12 лет."

export default () => {
  return (
    <div className="vp-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{TITLE}</title>
        <link
          href="https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/cropped-mp-icon-32x32.png" sizes="32x32"></link>
        <link
          rel="icon"
          href="/cropped-mp-icon-192x192.png"
          sizes="192x192"
        ></link>
        <link
          rel="apple-touch-icon-precomposed"
          href="/cropped-mp-icon-180x180.png"
        ></link>
        <meta
          name="msapplication-TileImage"
          content="/cropped-mp-icon-270x270.png"
        ></meta>
        <meta name="title" content={TITLE} />
        <meta name="description" content={DESCRIPTION}></meta>

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESCRIPTION} />

        <meta property="og:image" content="/meta_og_image.png" />
      </Helmet>
      <div className="vp-section vp-section_center">
        <img className="vp-logo" src="/logo.png" alt="Волшебное пианино" />
      </div>

      <div className="vp-section vp-section_center">
        <h1>
          Cовременная игровая музыкальная <br />
          методика изучения иностранных <br />
          языков для детей от 4 до 12 лет
        </h1>
      </div>

      <div className="vp-section vp-section_center">
        <Phone />
      </div>

      <div className="vp-section vp-section_center">
        <div className="vp-subtile">
          За&nbsp;последние годы были изданы 3&nbsp;книги, ставшие
          бестселлерами. По&nbsp;нашей методике занимаются и&nbsp;школьные
          учителя, и&nbsp;родители, и&nbsp;репетиторы по&nbsp;всей стране
          и&nbsp;даже за&nbsp;ее&nbsp;пределам! А&nbsp;теперь попробовать эффект
          &laquo;Волшебного пианино&raquo; на&nbsp;себе может каждый,
          у&nbsp;кого есть мобильное устройство.
        </div>
        <h3>Начните заниматься прямо сейчас!</h3>
        <div className="vp-download-buttons">
          <DownloadButton type="ios" />
          <DownloadButton type="google" />
        </div>
        <div className="vp-subtile vp-subtile_condensed">
          в приложении 62 интерактивных урока английского языка, а это целый год
          занятий с ребёнком.
        </div>
      </div>

      <div className="vp-section vp-section_center">
        <h3>ЭЛЕМЕНТЫ «ВОЛШЕБНОГО ПИАНИНО»</h3>
        <div className="vp-subtile">
          Нам удалось вовлечь в&nbsp;процесс обучения все виды памяти
          и&nbsp;восприятия, а&nbsp;не&nbsp;ориентироваться исключительно
          на&nbsp;зрительную память, как это обычно бывает в&nbsp;школе.
        </div>
        <Features />
      </div>

      <div className="vp-section_full">
        <FAQ />
      </div>

      <div className="vp-section vp-section_center">
        <h3>ОТЗЫВЫ</h3>
      </div>

      <div className="vp-section">
        <Reviews />
      </div>

      <div className="vp-section_full">
        <Videos />
      </div>

      <div className="vp-footer">
        &copy;{" "}
        <a className="vp-footer__link" href="https://volshebnoe-pianino.ru/">
          volshebnoe-pianino.ru
        </a>{" "}
        {CURRENT_YEAR}
      </div>
    </div>
  )
}
